import React, { FC, useState, useEffect, useCallback } from "react"
import { Box, Button, Chip, Grid, Typography } from "@mui/material"
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import format from "date-fns/format"
import formatDistanceStrict from "date-fns/formatDistanceStrict"
import { EmailLink, InnerLink, PhoneLink } from "src/components/ui"
import { Contractor } from "src/api"
import { Money, Switch } from 'src/components'
import CloseIcon from "@mui/icons-material/Close"
import { useEditContractorPaidPlanRequest, useEditContractorRequest } from "src/hooks/api"
import { useAddPopupMessage, useQueryParams } from "src/hooks/ui"
import { SearchParamsKeys } from "src/enums"

interface ContractorInfoProps {
  contractor: Contractor;
  activate: () => void;
  deactivate: () => void;
  isDeactivationInProgress: boolean;
  isActivationInProgress: boolean;
}

interface NotActivatedInfoProps {
  activate: () => void;
}

interface ActivatedInfoProps {
  contractor: Contractor;
  deactivate: () => void;
}

interface DeactivatedInfoProps {
  contractor: Contractor;
  activate: () => void;
  isActivationInProgress: boolean;
}

interface TrialPeriodInfoProps {
  contractor: Contractor;
}

interface PaidPlanInfoProps {
  contractor: Contractor;
}

const NotActivatedInfo: FC<NotActivatedInfoProps> = ({ activate }) => (
  <Grid container alignItems="center" spacing={1}>
    <Grid item>
      <CloseIcon color="error" />
    </Grid>
    <Grid item>
      <Typography fontWeight="medium" fontSize={14}>
        Owner not yet activated the account
      </Typography>
    </Grid>
    <Grid item>
      <Button onClick={() => activate()}>Activate</Button>
    </Grid>
  </Grid>
)

const ActivatedInfo: FC<ActivatedInfoProps> = ({ contractor, deactivate }) => {
  const activationDate = contractor.activationDate
    ? format(new Date(contractor.activationDate), "dd MMM yyyy")
    : ""

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <Typography fontWeight="medium" fontSize={14}>
          Account is activated on {activationDate} by {contractor.activatedBy}
        </Typography>
      </Grid>
      <Grid item>
        <Button color="error" onClick={() => deactivate()}>
          Revoke activation
        </Button>
      </Grid>
    </Grid>
  )
}

const DeactivatedInfo: FC<DeactivatedInfoProps> = ({
  contractor,
  activate,
  isActivationInProgress,
}) => {
  const deactivationDate = contractor.deactivationDate
    ? format(new Date(contractor.deactivationDate), "dd MMM yyyy")
    : ""

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <Typography fontWeight="medium" fontSize={14}>
          Account is deactivated on {deactivationDate} by{" "}
          {contractor.deactivatedBy}
        </Typography>
      </Grid>
      <Grid item>
        <Button onClick={() => activate()} disabled={isActivationInProgress}>
          {isActivationInProgress ? 'Activating...' : 'Activate'}
        </Button>
      </Grid>
    </Grid>
  )
}

const TrialPeriodInfo: FC<TrialPeriodInfoProps> = ({ contractor }) => {
  const [trialEndedAt, setTrialEndedAt] = useState<string>(contractor.trialEndedAt || '')
  useEffect(() => {
    setTrialEndedAt(contractor.trialEndedAt || '')
  }, [contractor])
  const addMessage = useAddPopupMessage()
  const request = useEditContractorRequest({
    id: contractor.id,
    options: {
      onSuccess: () => {
        addMessage({ text: "The end date of the Trial period has been successfully changed", type: "success" })
      },
      onError: ({ message: text }) => {
        addMessage({ text, type: "error" })
      },
    },
  })

  const onChange = (value: string | null) => {
    setTrialEndedAt(value || '')
    request.mutate({
      trialEndedAt: value || undefined
    })
  }

  return (
    <Box marginTop={2}>
      <Typography variant="h6">Trial Period</Typography>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          {
            trialEndedAt
              ? (
                <>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      onChange={onChange}
                      value={trialEndedAt}
                      disablePast={true}
                      renderInput={({ inputRef, inputProps, InputProps }) => {
                        return (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                              fontWeight="medium"
                              fontSize={14}
                            >
                                The end of the Trial period - {" "}
                              <Typography
                                ref={inputRef}
                                component="span"
                                fontWeight="medium"
                                fontSize={14}
                                {...inputProps}
                              >
                                {format(new Date(trialEndedAt), "MMM dd, yyyy")}
                                {` (${formatDistanceStrict(new Date(trialEndedAt), new Date(), { unit: 'day', addSuffix: true })})`}
                              </Typography>
                            </Typography>
                            {InputProps?.endAdornment}
                          </Box>
                        )
                      }}
                    />
                  </LocalizationProvider>
                </>
              )
              : <Typography fontWeight="medium" fontSize={14}>The Trial period has not been activated yet</Typography>
          }
        </Grid>
      </Grid>
    </Box>
  )
}

const PaidPlanInfo: FC<PaidPlanInfoProps> = ({ contractor }) => {
  const [isActivePaidPlan, setIsActivePaidPlan] = useState<boolean>(contractor.isActivePaidPlan || false)
  const addMessage = useAddPopupMessage()
  const request = useEditContractorPaidPlanRequest({
    id: contractor.id,
    options: {
      onSuccess: () => {
        addMessage({ text: "The status of the paid subcription has been successfully changed", type: "success" })
      },
      onError: ({ message: text }) => {
        addMessage({ text, type: "error" })
      },
    },
  })
  const onChange = (_: any, checked: boolean) => {
    setIsActivePaidPlan(checked)
    request.mutate({
      isActivePaidPlan: checked
    })
  }

  return (
    <Box marginTop={2}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <Typography variant="h6">Paid Subscription</Typography>
        </Grid>
        <Grid item alignSelf="flex-end">
          <Money />
        </Grid>
      </Grid>
      <Switch
        name="paidSubscription"
        label={isActivePaidPlan ? "Enabled" : "Disabled"}
        SwitchProps={{ checked: isActivePaidPlan, onChange }}
        FormControlLabelProps={{
          componentsProps: {
            typography: {
              fontWeight: "medium",
              fontSize: 14
            }
          }
        }}
      />
    </Box>
  )
}

export const ContractorInfo: FC<ContractorInfoProps> = ({
  contractor,
  activate,
  deactivate,
  isActivationInProgress,
}) => {
  const { address1, email, phone, deactivationDate, isAgreementAccepted, comment, labels, photo } = contractor
  const [params, setParams] = useQueryParams()

  const hasLabels = useCallback(() => {
    return labels && labels.length > 0
  }, [labels])()

  const isRootContractor = useCallback(() => contractor.isRoot === true, [contractor])()

  const renderContractorTypeBlock = () => {
    if (isRootContractor) {
      return (
        <Typography component="div">
          <Typography
            component="span"
            fontWeight="medium"
            fontSize={14}
          >
            Contractor is HQ for
          </Typography>
          <Typography
            component="span"
            fontWeight="medium"
            fontSize={14}
            color="#037BEB"
            sx={{ cursor: 'pointer' }}
            onClick={() => setParams({
              ...params,
              [SearchParamsKeys.Tab]: "Branches"
            })}
          >
            {` ${(contractor.branches || []).length} branches`}
          </Typography>
        </Typography>
      )
    }
    if (!isRootContractor && typeof contractor.rootContractorId === 'number') {
      return (
        <Typography component="div">
          <Typography
            component="span"
            fontWeight="medium"
            fontSize={14}
          >
            Contractor is a branch of
          </Typography>
          <Typography
            component="span"
            fontWeight="medium"
            fontSize={14}
          >
            <InnerLink to={`/contractors/${contractor.rootContractor?.id}`}>{` ${contractor.rootContractor?.name}`}</InnerLink>
          </Typography>
        </Typography>
      )
    }
    return (
      <Typography
        fontWeight="medium"
        fontSize={14}
      >
        This is a standalone contractor
      </Typography>
    )
  }

  const renderDashboardBlock = () => {
    if (contractor.isRoot) {
      return (
        <Grid item>
          <Typography variant="h6">Dashboard</Typography>
          <Typography component="div">
            <Typography
              variant="body2"
              component="span"
            >
                Dashboard 
            </Typography>
            <Typography
              variant="body2"
              component="span"
              color={contractor.isActiveDashboard ? 'green' : 'error'}
            >
              {contractor.isActiveDashboard ? ' enabled' : ' disabled'}
            </Typography>
          </Typography>
        </Grid>
      )
    }
    return null
  }

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item container direction="column" alignItems="stretch" spacing={2} xs={12} xl={6}>
        <Grid item>
          <img
            style={{
              objectFit: "scale-down",
              width: 100,
              height: 100
            }}
            src={photo ? photo : "../../no_image.png"}
            alt="Contractor logo"
          />
        </Grid>
        <Grid item>
          <Typography variant="h6">App branding</Typography>
          <Typography>{contractor.isEnabledAppBranding ? 'On' : 'Off'}</Typography>
        </Grid>
        <Grid item>
          <Typography component="span">
            <Typography variant="h6" display="inline">Contractor activation</Typography>
            <InnerLink
              to={`/contractors/${contractor.id}/activation-history`}
              style={{ textDecoration: "none", marginLeft: 15 }}
            >
              <Typography variant="body1" fontWeight={500} display="inline">History</Typography>
            </InnerLink>
          </Typography>
          {((!isAgreementAccepted && !deactivationDate) || !contractor.activationDate || !contractor.activatedBy) && (
            <NotActivatedInfo activate={activate} />
          )}
          {!isAgreementAccepted && deactivationDate && (
            <DeactivatedInfo contractor={contractor} activate={activate} isActivationInProgress={isActivationInProgress} />
          )}
          {isAgreementAccepted && contractor.activationDate && contractor.activatedBy && (
            <ActivatedInfo contractor={contractor} deactivate={deactivate} />
          )}
          <TrialPeriodInfo contractor={contractor} />
          <PaidPlanInfo contractor={contractor} />
        </Grid>
        {address1 && (
          <Grid item>
            <Typography variant="h6">Address</Typography>
            <Typography>{address1}</Typography>
          </Grid>
        )}
        {email && (
          <Grid item>
            <Typography variant="h6">Email</Typography>
            <EmailLink value={email} />
          </Grid>
        )}
        {phone && (
          <Grid item>
            <Typography variant="h6">Phone</Typography>
            <PhoneLink value={phone} />
          </Grid>
        )}
      </Grid>
      <Grid item container direction="column" alignItems="stretch" spacing={2} xs={12} xl={6}>
        <Grid item>
          <Typography variant="h5">Internal information</Typography>
        </Grid>
        {
          !comment && !hasLabels && (
            <Grid item>
              <Typography>There is no internal information for the contractor</Typography>
            </Grid>
          )
        }
        {comment && (
          <Grid item>
            <Typography variant="h6">Comment</Typography>
            <Typography>{comment}</Typography>
          </Grid>
        )}
        {hasLabels && (
          <Grid item>
            <Typography variant="h6">Labels</Typography>
            <Grid item container spacing={1} sx={{ mt: 0.5 }}>
              {(labels || []).map((label) => (
                <Grid item key={label.id}>
                  <Chip
                    variant="outlined"
                    color="primary"
                    label={label.name}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        <Grid item>
          <Typography variant="h6">HQ/Branches</Typography>
          {renderContractorTypeBlock()}
        </Grid>
        <Grid item>
          <Typography variant="h6">Wagner device firmware update</Typography>
          <Typography>{contractor.isEnabledWagnerDeviceFirmwareUpdate ? 'On' : 'Off'}</Typography>
        </Grid>
        {renderDashboardBlock()}
      </Grid>
    </Grid>
  )
}
